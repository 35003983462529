import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip'; // Updated import for Tooltip
import '../styles/CalendarComponent.css';

const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    axios.get('https://api.loocreations.com/events')
      .then(response => {
        const eventList = response.data.map(event => ({
          title: event.event_name,
          start: new Date(event.event_date),
          end: new Date(event.event_date),
          allDay: true,
          main_card_fights: event.main_card_fights,
          undercard_fights: event.undercard_fights,
          eventType: event.is_title_fight ? 'title-fight' : 'regular-fight' // Event color coding
        }));
        setEvents(eventList);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="calendar-container">
      <h1>UFC Events Calendar</h1>

      {/* Calendar Component */}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: '50px' }}
        views={['month']}
        defaultView="month"
        onSelectEvent={openModal}
        eventPropGetter={(event) => {
          const eventTypeStyle = event.eventType === 'title-fight' 
            ? { backgroundColor: '#ff4500' } 
            : { backgroundColor: '#444' };
          return { style: { ...eventTypeStyle, transition: '0.3s ease' } };
        }}
        components={{
          event: (event) => (
            <span data-tooltip-id={`tooltip-${event.title}`}>
              {event.title}
              <Tooltip id={`tooltip-${event.title}`} place="top" effect="solid">
                {event.title} on {moment(event.start).format('MMM Do YYYY')}
              </Tooltip>
            </span>
          ),
        }}
      />

      {/* Modal for event details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Event Details"
        ariaHideApp={false}
      >
        {selectedEvent && (
          <div>
            <h2>{selectedEvent.title}</h2>
            <p>Date: {selectedEvent.start.toLocaleDateString()}</p>

            <h3>Main Card Fights:</h3>
            <ul>
              {selectedEvent.main_card_fights.map((fight, index) => (
                <li key={index}>
                  {fight.fight} {fight.is_title_fight ? '(Title Fight)' : ''}
                </li>
              ))}
            </ul>

            <h3>Undercard Fights:</h3>
            <ul>
              {selectedEvent.undercard_fights.map((fight, index) => (
                <li key={index}>{fight.fight}</li>
              ))}
            </ul>

            <button onClick={closeModal}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CalendarComponent;
