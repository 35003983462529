import React, { useEffect, useState } from 'react';
import '../styles/main.css'; // Ensure this path is correct
import CalendarComponent from './CalendarComponent'; // Import the CalendarComponent

const MainContent = () => {
  const [posts, setPosts] = useState([]);
  const [activePost, setActivePost] = useState(null);

  useEffect(() => {
    fetch('https://api.loocreations.com/posts')
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error fetching posts:', error));
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const openModal = (post) => {
    setActivePost(post);
  };

  const closeModal = () => {
    setActivePost(null);
  };

  return (
    <main className="main">
      <h1 className="main-title">Brawler's Digest</h1>
      <p className="main-subtitle">Your Premier Source for MMA News and Insights!</p>
      
      {/* Flex container to place Calendar and Posts side by side */}
      <div className="content-wrapper">
        {/* Recent Posts Section */}
        <section className="posts-section">
          <h2 className="section-title">Latest Posts</h2>
          <div className="posts-grid">
            {posts.map(post => (
              <div key={post._id} className="post-card">
                {post.image && (
                  <img 
                    src={`https://api.loocreations.com/${post.image}`} 
                    alt={post.title} 
                    className="post-image"
                  />
                )}
                <div className="post-content">
                  <h3 className="post-title">{post.title}</h3>
                  <p className="post-excerpt">
                    {`${post.content.substring(0, 10000)}...`}
                  </p>
                  

                  <div className="post-meta">
                    <span className="post-author">Written by: {post.alias || 'Unknown Author'}</span>
                    <span className="post-date">{formatDate(post.createdAt)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Modal for displaying full post content */}
          {activePost && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <h2>{activePost.title}</h2>
                <img 
                  src={`https://api.loocreations.com/${activePost.image}`} 
                  alt={activePost.title} 
                  className="post-image"
                />
                <p>{activePost.content}</p>
                <span className="post-author">Written by: {activePost.alias || 'Unknown Author'}</span>
                <span className="post-date">{formatDate(activePost.createdAt)}</span>
              </div>
            </div>
          )}
        </section>

        {/* UFC Events Calendar Section */}
        <section className="calendar-section">
          <h2 className="section-title">Upcoming UFC Events</h2>
          <CalendarComponent /> {/* Render the CalendarComponent here */}
        </section>
      </div>
    </main>
  );
};

export default MainContent;
